/* league-spartan-700normal - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('League Spartan Bold normal'),
    local('League Spartan-Boldnormal'),
    url('./files/league-spartan-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/league-spartan-700.woff') format('woff'); /* Modern Browsers */
}

